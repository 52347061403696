import React, { useContext } from "react";
import "./menu.scss";
import { MenuContext } from "../context";
import logo from "../images/logoblanco-inventures.svg";
import menuImage from "../images/close-white.svg";
// import facebook from "../images/facebook.svg";
// import instagram from "../images/instagram.svg";
import linkedin from "../images/linkedin.svg";
import { Link } from "gatsby";
export default function Menu() {
  const { menu, toggleMenu, language, changeLanguage } = useContext(MenuContext);
  return (
    <div className={"menu" + (menu ? " active" : "")}>
      <div className="header-copy">
        <div className="left">
          <div className="logo">
            <img loading="lazy" src={logo} alt="" />
          </div>
        </div>
        <div className="right">
          <div className="languages">
            <div
              className={`language ${language !== "es" ? "disable" : ""}`}
              onClick={() => changeLanguage("es")}
            >
              ES
            </div>
            <div
              className={`language ${language !== "en" ? "disable" : ""}`}
              onClick={() => changeLanguage("en")}
            >
              EN
            </div>
          </div>
          <div
            className="menu-burger"
            onClick={toggleMenu}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                toggleMenu();
              }
            }}
            role="button"
            tabIndex={0}
          >
            <div className="square-black"></div>
            <img loading="lazy" src={menuImage} alt="" />
          </div>
        </div>
      </div>
      <div className="menu-links">
        <div
          className="link"
          onClick={toggleMenu}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              toggleMenu();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <Link to={"/"} activeClassName="active">
            <div className="text">{language === "es" ? "INICIO" : "HOME"}</div>
          </Link>
          <Link to={"/"}>
            <div className="message">{language === "es" ? "¿Qué hacemos?" : "What we do"}</div>
          </Link>
        </div>
        <div
          className="link"
          onClick={toggleMenu}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              toggleMenu();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <Link to={"/casos/"} activeClassName="active" partiallyActive={true}>
            <div className="text">{language === "es" ? "PROYECTOS" : "PROJECTS"}</div>
          </Link>

          <Link to={"/casos/"}>
            <div className="message">
              {language === "es" ? "¿Cómo lo hacemos?" : "How we do it"}
            </div>
          </Link>
        </div>
        <div
          className="link"
          onClick={toggleMenu}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              toggleMenu();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <Link to={"/equipo/"} activeClassName="active">
            <div className="text">{language === "es" ? "EQUIPO" : "TEAM"}</div>
          </Link>

          <Link to={"/equipo/"}>
            <div className="message">{language === "es" ? "¿Quiénes somos?" : "Who we are"}</div>
          </Link>
        </div>
      </div>
      <div className="separator"></div>
      <div className="footer">
        <div className="contact">
          <div className="title">{language === "es" ? "CONTACTO" : "CONTACT"}</div>
          <div className="text">Gath y Chaves 2446, Providencia, Santiago de Chile.</div>
          <div className="text">business@inventures.cl</div>
          <div className="text">recruiting@inventures.cl</div>
        </div>
        <div className="follow">
          <div className="title">{language === "es" ? "SÍGUENOS" : "FOLLOW"}</div>
          <div className="icons">
            {/* <img loading="lazy" src={facebook} alt="" />

            <img loading="lazy" src={instagram} alt="" /> */}
            <a href="https://cl.linkedin.com/company/inventures-spa">
              <img loading="lazy" src={linkedin} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
