import React, { PropsWithChildren, createContext, useMemo, useState } from "react";
import { Language } from "./types";
interface MenuContext {
  menu: boolean;
  language: Language;
  toggleMenu: () => void;
  changeLanguage: (newLanguage: Language) => void;
}

const menuContextDefaultValue: MenuContext = {
  menu: false,
  language: "es",
  toggleMenu: () => null,
  changeLanguage: (newLanguage) => {},
};

const scrollContextDefaultValue = {
  scroll: false,
  toggleScroll: (value: boolean) => {},
};

export const MenuContext = createContext(menuContextDefaultValue);

export const ScrollContext = createContext(scrollContextDefaultValue);

export const ScrollProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [scroll, setScroll] = useState(false);

  const toggleScroll = (value: boolean) => {
    setScroll(value);
  };

  return (
    <ScrollContext.Provider value={{ scroll, toggleScroll }}>{children}</ScrollContext.Provider>
  );
};

export const MenuProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [menu, setMenu] = useState(false);
  const [language, setLanguage] = useState<Language>("es");

  const changeLanguage = (newLanguage: Language) => {
    setLanguage(newLanguage);
  };

  const toggleMenu = () => {
    setMenu((prev) => !prev);
  };

  const value = useMemo(
    () => ({
      menu,
      language,
      toggleMenu,
      changeLanguage,
    }),
    [menu, language, toggleMenu, changeLanguage]
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};
