import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import logo from "images/logo-azul.svg";
import logoWhite from "images/logoblanco-inventures.svg";

import menuImage from "images/menu.svg";
import menuImageWhite from "images/menu-white.svg";

import { Menu } from "components";
import "./header.scss";

import { MenuContext, ScrollContext } from "../context";
import { Link } from "gatsby";

export default function Header() {
  let active = false;
  const { scroll, toggleScroll } = useContext(ScrollContext);

  window.addEventListener("scroll", (event) => {
    active =
      window.pageYOffset > 30 ||
      window.location.pathname === "/equipo/" ||
      window.location.pathname === "/casos/";
    toggleScroll(active);
  });

  useEffect(() => {
    active =
      window.pageYOffset > 30 ||
      window.location.pathname === "/equipo/" ||
      window.location.pathname === "/casos/";
    toggleScroll(active);
  }, [window.location.href]);

  const { toggleMenu, language, changeLanguage } = useContext(MenuContext);
  return (
    <header>
      <Menu />
      <div className={`header ${scroll ? "below" : ""}`}>
        <div className={`left`}>
          <Link to={`/`}>
            <div className="logo">
              <img className="normal" loading="lazy" src={logo} alt="" />
              <img className="white" loading="lazy" src={logoWhite} alt="" />
            </div>
          </Link>
        </div>
        <div className="right">
          <div className="menu-header">
            <Link to="/" activeClassName="active">
              <div className="item">HOME</div>
            </Link>
            <Link to="/casos/" activeClassName="active">
              <div className="item">{language === "es" ? "PROYECTOS" : "PROJECTS"}</div>
            </Link>
            <Link to="/equipo/" activeClassName="active">
              <div className="item">{language === "es" ? "EQUIPO" : "TEAM"}</div>
            </Link>
            {/* <Link
              to="https://inventures.medium.com/"
              activeClassName="active"
              target="_blank"
            >
              <div className="item">BLOG</div>
            </Link> */}
          </div>
          <div className={`languages`}>
            <div
              className={`language ${language === "es" ? "active" : ""}`}
              onClick={() => changeLanguage("es")}
            >
              ES
            </div>
            <div
              className={`language ${language === "en" ? "active" : ""}`}
              onClick={() => changeLanguage("en")}
            >
              EN
            </div>
          </div>
          <div
            className="menu-burger"
            onClick={() => {
              toggleMenu();
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                toggleMenu();
              }
            }}
            role="button"
            tabIndex={0}
          >
            <div className={`square-black`}></div>
            <img className="normal" loading="lazy" src={menuImage} alt="" />
            <img className="white" loading="lazy" src={menuImageWhite} alt="" />
          </div>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};
