import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

interface ImageProps {
  filename: string;
  class?: string;
  alt?: string;
}

const Image = (props: ImageProps) => (
  <StaticQuery
    query={graphql`
      query {
        images: allImageSharp {
          edges {
            node {
              fluid(maxWidth: 1920) {
                originalName
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n: any) => {
        return n.node.fluid.originalName.includes(props.filename);
      });
      if (!image) {
        return null;
      }

      return (
        <Img
          alt={props.alt}
          className={props.class}
          fluid={
            props.filename.split(".").pop() === "png" ? image.node.fluid : { ...image.node.fluid }
          }
        />
      );
    }}
  />
);

export default Image;
