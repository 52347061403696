import React, { useContext } from "react";
import { MenuContext } from "context";

import "./footer.scss";
// import facebook from "../images/facebook.svg";
// import instagram from "../images/instagram.svg";
import linkedin from "images/linkedin.svg";
import logoBlue from "images/logo-blue.svg";
import { Link } from "gatsby";

export default function Footer() {
  const { language } = useContext(MenuContext);

  return (
    <footer>
      <img src={logoBlue} className="logo" alt="" />
      <div className="info">
        <div className="footer-menu">
          <div className="title"> {language === "es" ? "NOSOTROS" : "US"}</div>
          <div className="text">
            <Link to={"/"}>
              <div className="bold">{language === "es" ? "INICIO" : "HOME"}</div>—
              <span>{language === "es" ? "¿Qué hacemos?" : "What we do"}</span>
            </Link>
          </div>
          <div className="text">
            <Link to={"/casos/"}>
              <div className="bold">{language === "es" ? "PROYECTOS" : "PROJECTS"}</div>—
              <span>{language === "es" ? "¿Cómo lo hacemos?" : "How we do it"}</span>
            </Link>
          </div>
          <div className="text">
            <Link to={"/equipo/"}>
              <div className="bold">{language === "es" ? "EQUIPO" : "TEAM"}</div>—
              <span>{language === "es" ? "¿Quiénes somos?" : "Who we are"}</span>
            </Link>
          </div>
        </div>
        <div className="contact">
          <div className="title">{language === "es" ? "CONTACTO" : "CONTACT"}</div>
          <div className="text">Gath y Chaves 2446, Providencia, Santiago de Chile.</div>
          <div className="email">business@inventures.cl</div>
          <div className="email">recruiting@inventures.cl</div>
        </div>
        <div className="follow">
          <div className="title"> {language === "es" ? "SÍGUENOS" : "FOLLOW"}</div>
          <div className="icons">
            {/* <img src={facebook} className="facebook" alt="facebook" />
            <img src={instagram} className="instagram" alt="instagram" /> */}
            <a href="https://cl.linkedin.com/company/inventures-spa">
              <img src={linkedin} className="linkedin" alt="linkedin" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
