import React from "react";
import Layout from "./src/components/layout";
import { GlobalStyles } from "./src/styles/index";
import { MenuProvider, ScrollProvider } from "./src/context";
import type { GatsbyBrowser } from "gatsby";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => {
  return (
    <ScrollProvider>
      <MenuProvider>
        <GlobalStyles />
        <Layout>{element}</Layout>
      </MenuProvider>
    </ScrollProvider>
  );
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = () => {
  if (typeof window !== `undefined`) {
    window.scrollTo(0, 0);
  }
};

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = () => false;
