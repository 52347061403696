/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useContext, PropsWithChildren } from "react";
import PropTypes from "prop-types";
import { Header, Footer } from "./index";
import AOS from "aos";
import "./layout.scss";
import { MenuContext } from "../context";

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const { changeLanguage } = useContext(MenuContext);
  useEffect(() => {
    AOS.init({ once: true });
    const userLang = navigator.language;
    if (userLang.includes("es")) {
      changeLanguage("es");
    } else {
      changeLanguage("en");
    }
  }, []);
  return (
    <>
      <link rel="stylesheet" href="https://unpkg.com/aos@next/dist/aos.css" />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
